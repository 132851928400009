<template>
  <div class="px-4 px-lg-9 py-8 create-bans">
    <v-row>
      <v-col>
        <v-data-table
          v-if="banTypes.length"
          :headers="headers" :hide-default-footer="true"
          :items="banTypes" :item-class="item => item.last ? 'create-bans__last-item' : null" :items-per-page="-1" dense class="table--has-pointer"
        >
          <template #[`item.reason`]="{ item }">
            <span class="d-block text-truncate create-bans__reason">{{ item.reason }}</span>
          </template>
          <template #[`item.matchCount`]="{ item }">
            <div class="d-flex justify-space-between align-center">
              <span>{{ item.matchCount }}</span>
              <v-menu v-if="!item.last">
                <template #activator="{ on, attrs }">
                  <v-btn color="primary" icon v-bind="attrs" v-on="on" @click.prevent @mousedown.stop>
                    <v-icon v-text="'more_vert'" />
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="showEditBanTypeDialog = true; currentBanTypeSelected = item">
                    <v-list-item-title>{{ $t('common.edit') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="
                      currentBanTypeSelected = item;
                      $confirm($t('project.competition.bans.confirmDeleteBanType')).then(confirm => confirm && deleteBanType())
                    "
                  >
                    <v-list-item-title>{{ $t('common.delete') }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
        </v-data-table>
        <empty-list v-else :content="$t('competition.sections.createBans')" />
        <v-btn outlined depressed color="primary" class="mt-6" @click="currentBanTypeSelected = null; showEditBanTypeDialog = true">
          <v-icon small color="primary" v-text="'add'" />
          <h5 class="mx-4" v-text="$t('common.add')" />
        </v-btn>
        <edit-ban-type-dialog
          v-if="showEditBanTypeDialog"
          :ban-type="currentBanTypeSelected" :organization-id="organizationId" :project-id="projectId" @closeDialog="showEditBanTypeDialog = false"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CreateBans',
  components: {
    EmptyList: () => import('@/components/EmptyList'),
    EditBanTypeDialog: () => import('@/modules/competition/bans/EditBanTypeDialog'),
  },
  props: {
    organizationId: { type: String, default: null },
    projectId: { type: String, default: null },
  },
  data: () => ({ showEditBanTypeDialog: false, currentBanTypeSelected: null }),
  computed: {
    ...mapGetters({ rawBanTypes: 'competition/banTypes' }),
    headers() {
      return [
        { text: this.$t('project.competition.bans.reason'), value: 'reason' },
        { text: this.$t('project.competition.bans.matchCount'), value: 'matchCount' },
      ]
    },
    banTypes() {
      return [
        ...this.rawBanTypes,
        {
          reason: this.$t('project.competition.bans.sportsCommittee'),
          matchCount: this.$t('project.competition.bans.determinedAfterCommitteeMeeting'),
          last: true,
        },
      ]
    },
  },
  methods: {
    deleteBanType() {
      const { organizationId, projectId, currentBanTypeSelected } = this
      this.runAsync(() => { this.$store.dispatch('competition/deleteBanType', { organizationId, projectId, banTypeId: currentBanTypeSelected.id }) })
    },
  },
}
</script>
<style lang="scss" scoped>
.create-bans__reason {
  max-width: 6rem;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    max-width: inherit;
  }
}

::v-deep .create-bans__last-item td {
  background: $inactive;
}
</style>
